module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"239103752","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GS_portfollio","short_name":"GS","start_url":"/","description":"My portfollio","orientation":"portrait","background_color":"#0ea5e9","theme_color":"#0ea5e9","display":"standalone","icon":"./src/images/favicon.png","icon_options":{"purpose":"maskable"},"cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"160d3ddf63e5ec10a656070e2301fdf1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/en/home","/fr/bienvenu","/en/home#*","/fr/bienvenu#*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl-v4/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/runner/work/gs-portfollio/gs-portfollio/src/intl","languages":["en","fr"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
