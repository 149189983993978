import './node_modules/sal.js/dist/sal.css';

import React from 'react';
import { RecoilRoot } from 'recoil';
import sal from 'sal.js';

export const onRouteUpdate = () => {
	sal();
};

export const wrapRootElement = ({ element, props }) => (
	<RecoilRoot {...props}>{element}</RecoilRoot>
);

export const onServiceWorkerUpdateReady = () => window.location.reload();
